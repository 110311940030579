import { Pass } from '@canalplus/mycanal-sdk';
import { IAPICredentialsRaw } from '@canalplus/oneplayer-types';
import { useSelector } from 'react-redux';
import { getEncodedPassId } from '../../../helpers/user/user-helper';
import { tokenCMSSelector } from '../../../selectors/application/application-selectors';
import {
  abTestingPopulationSelector,
  microEligibilitySelector,
  passTokenSelector,
  profileIdSelector,
} from '../../../store/slices/user-selectors';

export const useGetPlayerCredentials = (): IAPICredentialsRaw => {
  return {
    abTestPopulation: String(useSelector(abTestingPopulationSelector) || ''),
    appSessionId: Pass.sessionId,
    deviceId: Pass.getDeviceIdValues() ? Pass.getDeviceIdValues().deviceId : '',
    microEligibility: useSelector(microEligibilitySelector),
    passToken: useSelector(passTokenSelector),
    passId: getEncodedPassId(),
    profileId: useSelector(profileIdSelector)?.toString(),
    tokenCMS: useSelector(tokenCMSSelector),
    trackingKeyId: Pass.getDeviceIdValues() ? Pass.getDeviceIdValues().trackingKeyId : '',
  };
};
