import { useState } from 'react';
import { setIsPaused } from '../actions';
import { useVideoDispatch } from './useVideoDispatch';
import { useVideoState } from './useVideoState';

/**
 * Handle Video logic about pause/play
 * Useful to pause the Video by an external event (e.g. opening a modal)
 */
export const useToggleIsPaused = (): {
  pause: () => void;
  play: () => void;
} => {
  const { isFullyPlayed, isEnded, isPaused } = useVideoState();
  const [isPausedByHook, setIsPausedByHook] = useState(false);
  const videoDispatch = useVideoDispatch();

  const pause = () => {
    if (!isPaused && !isEnded && !isFullyPlayed && !isPausedByHook) {
      videoDispatch(setIsPaused(true));
      setIsPausedByHook(true);
    }
  };

  const play = () => {
    if (isPausedByHook) {
      videoDispatch(setIsPaused(false));
      setIsPausedByHook(false);
    }
  };

  return {
    pause,
    play,
  };
};
