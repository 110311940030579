import { VideoState } from './types';

export const INITIAL_STATE: VideoState = {
  isFullyPlayed: false,
  isEnded: false,
  isFullFrame: false,
  keepFullFrameOnReset: false,
  isPaused: true,
  isPlayerReadyToPlay: false,
  isWaitingTimeBeforePlayingOver: false,
  playerInstance: null,
};
