import { MinimalPlayerContextProvider } from '@canalplus/oneplayer-shared-components';
import { createContext, useMemo, useReducer } from 'react';
import { VideoAction } from './actions';
import { INITIAL_STATE } from './constants';
import videoReducer from './reducer';
import { VideoState } from './types';

export type VideoContext = {
  state: VideoState;
  dispatch: React.Dispatch<VideoAction>;
};

const videoContext = createContext<VideoContext | null>(null);

const { Provider } = videoContext;

function VideoProvider({
  children,
  isFullFrame = false,
  isFullyPlayed = false,
  keepFullFrameOnReset = false,
}: {
  children: React.ReactNode;
  isFullFrame?: boolean;
  isFullyPlayed?: boolean;
  keepFullFrameOnReset?: boolean;
}): JSX.Element {
  const [state, dispatch] = useReducer(videoReducer, {
    ...INITIAL_STATE,
    isFullyPlayed,
    isFullFrame,
    keepFullFrameOnReset,
  });

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );

  return (
    <Provider value={value}>
      <MinimalPlayerContextProvider minimalPlayer={value.state.playerInstance}>{children}</MinimalPlayerContextProvider>
    </Provider>
  );
}

videoContext.displayName = 'videoContext';

export { videoContext, VideoProvider };
