import { ActionType, VideoAction } from './actions';
import { INITIAL_STATE } from './constants';
import { VideoState } from './types';

const videoReducer = (state: VideoState, action: VideoAction): VideoState => {
  switch (action.type) {
    case ActionType.RESET_VALUES:
      return {
        ...INITIAL_STATE,
        keepFullFrameOnReset: state.keepFullFrameOnReset, // Persist keepFullFrameOnReset value
        ...(state.keepFullFrameOnReset && { isFullFrame: state.isFullFrame }),
      };

    case ActionType.SET_IS_ENDED:
      return {
        ...state,
        isEnded: action.payload.isEnded,
      };

    case ActionType.SET_IS_FULL_FRAME:
      return {
        ...state,
        isFullFrame: action.payload.isFullFrame,
      };

    case ActionType.SET_IS_PAUSED:
      return {
        ...state,
        isPaused: action.payload.isPaused,
      };

    case ActionType.SET_IS_PLAYER_READY_TO_PLAY:
      return {
        ...state,
        isPlayerReadyToPlay: action.payload.isPlayerReadyToPlay,
        isPaused: !action.payload.isPlayerReadyToPlay || !state.isWaitingTimeBeforePlayingOver,
      };

    case ActionType.SET_IS_WAITING_TIME_BEFORE_PLAYING_OVER:
      return {
        ...state,
        isWaitingTimeBeforePlayingOver: action.payload.isWaitingTimeBeforePlayingOver,
        isPaused: !action.payload.isWaitingTimeBeforePlayingOver || !state.isPlayerReadyToPlay,
      };

    case ActionType.SET_PLAYER_INSTANCE:
      return {
        ...state,
        playerInstance: action.payload.playerInstance,
      };

    case ActionType.SET_KEEP_FULL_FRAME_ON_RESET:
      return {
        ...state,
        keepFullFrameOnReset: action.payload.keepFullFrameOnReset,
      };

    default:
      return state;
  }
};

export default videoReducer;
